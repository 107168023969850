import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Container,
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import { SecondaryPagesHero, Seo } from "../../components/elements"

const ReferPatient = ({ data, pageContext }) => {
  const page = data.datoCmsCancerMedicalProf
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title="Refer a Patient"
        description="Our easy to use online physician portal offers access and convenience you won’t find anywhere else in the state. The many benefits of WVUChart.com include:"
      />

      <Main>
        <SecondaryPagesHero page={page} title="Refer a Patient">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" / "
            crumbLabel="refer a patient"
          />
        </SecondaryPagesHero>

        {/* IntroContent */}
        <Section id="intro">
          <Container>
            <Flex flexWrap="wrap" justifyContent="center">
              <Box width={["full", "full", 3 / 4]}>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: page.referAPatientNode.childMarkdownRemark.html,
                  }}
                />
              </Box>
            </Flex>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default ReferPatient

export const query = graphql`
  {
    datoCmsCancerMedicalProf {
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      referAPatientNode {
        childMarkdownRemark {
          html
        }
      }
      title
    }
  }
`
